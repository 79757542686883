exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-committee-js": () => import("./../../../src/pages/committee.js" /* webpackChunkName: "component---src-pages-committee-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-discover-contact-us-js": () => import("./../../../src/pages/discover/contact-us.js" /* webpackChunkName: "component---src-pages-discover-contact-us-js" */),
  "component---src-pages-discover-guide-js": () => import("./../../../src/pages/discover/guide.js" /* webpackChunkName: "component---src-pages-discover-guide-js" */),
  "component---src-pages-discover-index-js": () => import("./../../../src/pages/discover/index.js" /* webpackChunkName: "component---src-pages-discover-index-js" */),
  "component---src-pages-discover-park-js": () => import("./../../../src/pages/discover/park.js" /* webpackChunkName: "component---src-pages-discover-park-js" */),
  "component---src-pages-discover-resources-js": () => import("./../../../src/pages/discover/resources.js" /* webpackChunkName: "component---src-pages-discover-resources-js" */),
  "component---src-pages-employment-js": () => import("./../../../src/pages/employment.js" /* webpackChunkName: "component---src-pages-employment-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industry-supplier-js": () => import("./../../../src/pages/industry-supplier.js" /* webpackChunkName: "component---src-pages-industry-supplier-js" */),
  "component---src-pages-join-now-js": () => import("./../../../src/pages/join-now.js" /* webpackChunkName: "component---src-pages-join-now-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-news-events-js": () => import("./../../../src/pages/news-events.js" /* webpackChunkName: "component---src-pages-news-events-js" */),
  "component---src-templates-employment-details-js": () => import("./../../../src/templates/employment-details.js" /* webpackChunkName: "component---src-templates-employment-details-js" */),
  "component---src-templates-industry-supplier-detail-js": () => import("./../../../src/templates/industry-supplier-detail.js" /* webpackChunkName: "component---src-templates-industry-supplier-detail-js" */),
  "component---src-templates-news-details-js": () => import("./../../../src/templates/news-details.js" /* webpackChunkName: "component---src-templates-news-details-js" */),
  "component---src-templates-pages-corporate-js": () => import("./../../../src/templates/pages-corporate.js" /* webpackChunkName: "component---src-templates-pages-corporate-js" */),
  "component---src-templates-pages-discover-js": () => import("./../../../src/templates/pages-discover.js" /* webpackChunkName: "component---src-templates-pages-discover-js" */),
  "component---src-templates-park-category-js": () => import("./../../../src/templates/park-category.js" /* webpackChunkName: "component---src-templates-park-category-js" */),
  "component---src-templates-park-details-js": () => import("./../../../src/templates/park-details.js" /* webpackChunkName: "component---src-templates-park-details-js" */),
  "component---src-templates-park-region-js": () => import("./../../../src/templates/park-region.js" /* webpackChunkName: "component---src-templates-park-region-js" */),
  "component---src-templates-resource-details-js": () => import("./../../../src/templates/resource-details.js" /* webpackChunkName: "component---src-templates-resource-details-js" */)
}

